//  External deps
import React, { useEffect } from "react";

//  Internal deps
import {
    Element,
    Row,
    Portion,
    Heading,
    Text, CodeBlock,
} from "fictoan-react";

//  Local components
import { PhilosophyStyled } from "./Philosophy.styled";

//  Local assets

export const Philosophy = () => {
    useEffect(() => {
        document.title = "The philosophy behind UNISpec";

        try {
            window.scroll({
                top: 0,
                left: 0
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <PhilosophyStyled>
            {/*  /////////////////////////////////////////////////////////////////////////////////////////////////  */}
            {/*  INTRO  */}
            {/*  /////////////////////////////////////////////////////////////////////////////////////////////////  */}
            <Element as="section" id="hero">
                <Row sidePadding="huge" gutters="large" marginTop="small">
                    <Portion desktopSpan="half">
                        <Heading as="h3" marginBottom="nano">UNISpec philosophy</Heading>
                        <Heading as="h6">Universal Notation for Information</Heading>
                    </Portion>

                    <Portion desktopSpan="half">
                        <Text textColour="black" marginBottom="nano">
                            Standardised guidelines for structuring your APIs, aimed at making them more intuitive to
                            read and integrate.
                        </Text>
                    </Portion>
                </Row>
            </Element>

            <Element as="section">
                <Row sidePadding="huge" gutters="large" marginTop="small">
                    <Portion desktopSpan="half">
                        <Heading as="h6">The world is shrinking</Heading>
                    </Portion>

                    <Portion desktopSpan="half">
                        <Text>
                            More people now have access to digital services than ever before. And companies are racing to provide everything from entertainment to banking to education to the newly digitally initiated.
                        </Text>
                    </Portion>
                </Row>

                <Row sidePadding="huge" gutters="large" marginTop="small">
                    <Portion desktopSpan="half">
                        <Heading as="h6">APIs to the rescue</Heading>
                    </Portion>

                    <Portion desktopSpan="half">
                        <Text marginBottom="micro">
                            Now, the only way to cater to the needs of the next billion is with APIs. Closed, isolated systems do not scale. We need more and more discrete systems talking to each other, and sharing data between themselves, on-demand. This means more integrations, and inventing more efficient ways of sharing said information.
                        </Text>

                        <Text>
                            These integrations, however, in the current world, is a pain to setup and maintain. Isolated, walled-off systems are opening up to the rest of the world for the first time ever, and exposing a wealth of information for the first time.
                        </Text>
                    </Portion>
                </Row>

                <Row sidePadding="huge" gutters="large" marginTop="small">
                    <Portion desktopSpan="half">
                        <Heading as="h6">Enter UNISpec</Heading>
                    </Portion>

                    <Portion desktopSpan="half">
                        <Text marginBottom="micro">
                            UNISpec tries to offer a basic set of guidelines to design and structure these APIs to make them intuitive and coherent. The idea is that if the data itself is structured well, it could potentially reduce integration time and effort.
                        </Text>

                        <Text>The information in an API should convey a story, not muddled data.</Text>
                    </Portion>
                </Row>
            </Element>
        </PhilosophyStyled>
    );
}
