//  External deps
import { lighten } from "polished";
import { createGlobalStyle } from "styled-components";

//  Internal deps

//  Local components
import { UNISpecBrandColours } from "./UNISpecBrandColours";

//  Local assets


export const GlobalDarkStyles = createGlobalStyle`
    h1, h2, h3 { line-height : 1; }

    p { margin : 0 }

    article  {
        min-height     : 100vh;
        display        : flex; 
        flex-direction : column;
    }

    div[class*="border"] { border-width : 1px !important; }

    //  SECTOR CARD  //////////////////////////////////////////////////////////
    .sector-card {
        padding : 8px 16px !important;

        p::after {
            display     : none;
            content     : "\\2192";
            margin-left : 8px;
        }
        
        &:hover {
            p::after { display : inline-block; }
        }
    }
`;
