//  External dependencies
import React, { useState } from "react";
import {
    BrowserRouter,
    Switch as RouteSwitch,
    Route
} from "react-router-dom";

//  Internal dependencies
import { defaultColours, ThemeProvider } from "fictoan-react";

//  Local pages
import { Home } from "./pages/Home/Home";
import { Philosophy } from "./pages/Philosophy/Philosophy";
import { AppWithSidebar } from "./AppWithSidebar";

//  Local components
import { GlobalFonts } from "./assets/fonts/fonts";
import { UNISpecLightTheme } from "./styles/UNISpec.lightTheme";
import { UNISpecDarkTheme } from "./styles/UNISpec.dark.theme";
import { GlobalLightStyles } from "./styles/Global.light.styled";
import { GlobalDarkStyles } from "./styles/Global.dark.styled";

//  Local assets
import { ReactComponent as IconTheme } from "./assets/icons/theme.svg";

export const App = (props) => {

    const { location } = props;

    let [currentTheme, setCurrentTheme] = useState("light");

    const toggleTheme = () => {
        if (currentTheme === "light") {
            setCurrentTheme("dark");
        } else {
            setCurrentTheme("light");
        }
    }

    return (
        <ThemeProvider
            theme={currentTheme === "light" ? UNISpecLightTheme : UNISpecDarkTheme}
        >
            { UNISpecLightTheme && <GlobalLightStyles /> }
            { UNISpecLightTheme && <GlobalDarkStyles /> }

            <GlobalFonts />

            <BrowserRouter>
                <IconTheme
                    src={IconTheme} className="theme-toggle"
                    onClick={toggleTheme}
                />

                <RouteSwitch location={location}>
                    <Route
                        exact
                        path="/"
                        component={ Home }
                    />

                    <Route
                        exact
                        path="/philosophy"
                        component={ Philosophy }
                    />

                    <Route
                        path="*"
                        component={ AppWithSidebar }
                    />
                </RouteSwitch>
            </BrowserRouter>
        </ThemeProvider>
    );
}
