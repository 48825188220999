//  External deps
import React, { useEffect } from "react";

//  Internal deps
import {
    Element,
    Row,
    Portion,
    Heading,
    Text, CodeBlock,
} from "fictoan-react";

//  Local components
import { HomeStyled } from "./Home.styled";

//  Local assets
import {
    AvoidShortForms,
    EstablishHierarchy,
    GroupSiblings,
    CreateComponents
} from "./snippets"
import { Link } from "react-router-dom";

export const Home = () => {
    useEffect(() => {
        document.title = "UNISpec";

        try {
            window.scroll({
                top: 0,
                left: 0
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <HomeStyled>
            {/*  /////////////////////////////////////////////////////////////////////////////////////////////////  */}
            {/*  INTRO  */}
            {/*  /////////////////////////////////////////////////////////////////////////////////////////////////  */}
            <Element as="section" id="hero">
                <Row sidePadding="huge" gutters="large" marginTop="small">
                    <Portion desktopSpan="half">
                        <Heading as="h3" marginBottom="nano">UNISpec</Heading>
                        <Heading as="h6">Universal Notation for Information — v0.1.0</Heading>
                    </Portion>

                    <Portion desktopSpan="half">
                        <Text textColour="black" marginBottom="nano">
                            Standardised guidelines for structuring your APIs, aimed at making them more intuitive to
                            read and integrate.
                        </Text>

                        <Link to="/philosophy">Philosophy &rarr;</Link>
                    </Portion>
                </Row>
            </Element>

            <Element as="section" marginTop="small">
                <Row sidePadding="huge" gutters="large">
                    <Portion desktopSpan="half">
                        <Heading as="h5" marginBottom="nano">1. Avoid short forms</Heading>
                        <Text marginBottom="micro">
                            Abbreviations, acronyms and dropping of vowels in the keys only add to ambiguity. Sure, it might become a bit verbose, but it’s better to dispel confusion at the cost of character count.
                        </Text>

                        <CodeBlock source={AvoidShortForms} />
                    </Portion>
                </Row>

                <Row sidePadding="huge" gutters="large">
                    <Portion desktopSpan="half">
                        <Heading as="h5" marginBottom="nano">2. Establish hierarchy</Heading>
                        <Text marginBottom="micro">
                            A flat JSON might be easier to skim through, but a clear hierarchy is a lot easier to <em>understand</em>. The relationships become obvious, and over time, intuitive.
                        </Text>

                        <CodeBlock source={EstablishHierarchy} />
                    </Portion>
                </Row>

                <Row sidePadding="huge" gutters="large">
                    <Portion desktopSpan="half">
                        <Heading as="h5" marginBottom="nano">3. Group siblings together</Heading>
                        <Text marginBottom="micro">
                            If you have many similar objects with similar children, consider them as siblings, at the same level.
                        </Text>

                        <CodeBlock source={GroupSiblings} />
                    </Portion>
                </Row>

                <Row sidePadding="huge" gutters="large">
                    <Portion desktopSpan="half">
                        <Heading as="h5" marginBottom="nano">4. Create components</Heading>
                        <Text marginBottom="micro">
                            Consider bundling repetitive data into reusable component objects. Below, we’ve created a `user` object, that is repeated wherever we refer to a person. This makes it easy to deterministically build the UI later on.
                        </Text>

                        <CodeBlock source={CreateComponents} />
                    </Portion>
                </Row>

                <Row sidePadding="huge" gutters="large">
                    <Portion desktopSpan="half">
                        <Heading as="h5" marginBottom="nano">5. Respond with the correct HTTP code</Heading>
                        <Text marginBottom="micro">
                            Use codes like <code>201 CREATED</code> and <code>202 ACCEPTED</code> to make your responses more descriptive and meaningful.
                        </Text>
                    </Portion>
                </Row>

                <Row sidePadding="huge" gutters="large">
                    <Portion desktopSpan="half">
                        <Heading as="h5" marginBottom="nano">6. Use kebab-base for URIs</Heading>
                        <Text marginBottom="micro">
                            Use URIs and endpoints are more readable with lower kebab-case.
                        </Text>
                    </Portion>
                </Row>
            </Element>
        </HomeStyled>
    );
}
