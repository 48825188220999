import { lighten } from "polished";

import { defaultColours } from "fictoan-react";

import { UNISpecBrandColours } from "./UNISpecBrandColours";


export const UNISpecDarkTheme = {
    body : {
        bg : UNISpecBrandColours.shade
    },

    text : {
        font : {
            sans  : "Space Grotesk, sans-serif",
            mono  : "JetBrains Mono, monospace",
        },
        paras: {
            font       : "Space Grotesk, sans-serif",
            lineHeight : 1.56,
            color      : `${lighten(0.56, UNISpecBrandColours.shade)}`,
        },
        headings : {
            font  : "Space Grotesk, sans-serif",
            color : UNISpecBrandColours.hue,
        },
        code : {
            inline : {
                bg    : defaultColours.teal30,
                text  : defaultColours.blue90,
            },
            block : {
                bg         : `${lighten(0.02, UNISpecBrandColours.shade)}`,
                text       : defaultColours.blue70,
                scale      : 80,
                lineHeight : 1.8
            },
            prism : {
                tokens : {
                    property : defaultColours.green90,
                    plain    : defaultColours.slate
                },
                languages : {
                    json : {
                        tokens   : {
                            string : defaultColours.red70,
                        }
                    }
                }
            }
        }
    },

    //  BUTTON  ///////////////////////////////////////////////////////////////
    button : {
        fontFamily : "Space Grotesk, sans-serif",
    },

    //  CARD  /////////////////////////////////////////////////////////////////
}
