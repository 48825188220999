export const AvoidShortForms = `{
    "res" : "Some string",
    "rslt" : "Another string",
    "desc" : "One more string"
},
    
can be turned into
    
{
    "response" : "Some string",
    "result" : "Another string",
    "description" : "One more string"
}`
;

export const EstablishHierarchy = `{
    "custName" : "Sherlock Holmes",
    "custProfession" : "Private Detective",
    "custAddLn1" : "221-B"
    "custAddLn2" : "Baker Street"
},
    
can be tweaked to
    
{
    "customer" : {
        "name" : "Sherlock Holmes",
        "profession" : "Private Detective",
        "address" : {
            "line1" : "221-B"
            "line2" : "Baker Street"
        }
    }
}`
;

export const GroupSiblings = `{
    "billingAddLn1" : "Suite 45, Bloomington Towers",
    "billingAddLn2" : "89, Norwood Avenue",
    "billingCity" : "Metropolis",
    "billingPinCode" : 89233,
    "shippingAddLn1" : "P.O. Box 887",
    "shippingAddLn2" : "2508 Dolor Street",
    "shippingCity" : "Zion",
    "shippingPinCode" : 23163
},
    
can be written as
    
"addresses" : [
    "billing" : {
        "line1" : "Suite 45, Bloomington Towers",
        "line2" : "89, Norwood Avenue",
        "city" : "Metropolis",
        "pin" : 89233
    },
    "shipping" : {
        "line1" : "P.O. Box 887",
        "line2" : "2508 Dolor Street",
        "city" : "Zion",
        "pin" : 23163
    }    
]`
;

export const CreateComponents = `{
    "reportedBy" : "Chris Nielson",
    "reportedAt" : "2020-306T10:45:00Z",
    "reporterRole" : "SBE",
    "assignee" : "Katherine Hanlon",
    "assignedAt" : "2020-306T03:24:00Z",
    "assigneeRole" : "JBE",
    "resolvedBy" : "Alicia Jovic",
    "resolverRole" : "SBE",
    "resolvedAt" : "2020-307T11:16:00Z",
},
    
can be restructured as
    
{
    "reported" : {
        "time" : "2020-306T10:45:00Z",
        "user" : {
            "name" : "Chris Nielson",
            "role" : "JBE"
        }
    },
    "assigned" : {
        "time" : "2020-306T03:24:00Z",
        "user" : {
            "name" : "Katherine Hanlon",
            "role" : "JBE"
        }
    },
    "resolved" : {
        "time" : "2020-307T11:16:00Z",
        "user" : {
            "name" : "Alicia Jovic",
            "role" : "SBE"
        }
    },
}`
;
