//  External deps
import { lighten } from "polished";
import { createGlobalStyle } from "styled-components";

//  Internal deps

//  Local components
import { UNISpecBrandColours } from "./UNISpecBrandColours";
import { defaultColours } from "fictoan-react";

//  Local assets


export const GlobalLightStyles = createGlobalStyle`
    h1, h2, h3 { line-height : 1; }
    
    p { margin : 0 }

    article  {
        min-height     : 100vh;
        display        : flex; 
        flex-direction : column;
    }

    div[class*="border"] { border-width : 1px !important; }
    
    .theme-toggle {
        position        : fixed;
        left            : 16px;
        bottom          : 16px;
        width           : 24px;
        cursor          : pointer;
        fill            : none;
        stroke          : ${UNISpecBrandColours.hue};
        stroke-linecap  : round;
        stroke-linejoin : round;
        stroke-width    : 2px;
    }

    //  SECTOR CARD  //////////////////////////////////////////////////////////
    .sector-card {
        padding : 8px 16px !important;

        p::after {
            display     : none;
            content     : "\\2192";
            margin-left : 8px;
        }
        
        &:hover {
            p::after { display : inline-block; }
        }
    }
`;
