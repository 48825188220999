import styled from "styled-components";
import { UNISpecBrandColours } from "../../styles/UNISpecBrandColours";


export const HomeStyled = styled.article`
    #hero {
        background-color : ${UNISpecBrandColours.hue};

        h3,
        h6 { color: ${UNISpecBrandColours.shade} }
    }
`;
