import { lighten } from "polished";

import { defaultColours } from "fictoan-react";
import { UNISpecBrandColours } from "./UNISpecBrandColours";

export const mainColors = {
    hue      : UNISpecBrandColours.hue,
    tint     : defaultColours.amber,
    shade    : defaultColours.grey,
    analogue : defaultColours.indigo50,
    accent   : defaultColours.green80,
}

export const UNISpecLightTheme = {
    text : {
        font : {
            sans  : "Space Grotesk, sans-serif",
            mono  : "JetBrains Mono, monospace",
        },
        paras: {
            font       : "Space Grotesk, sans-serif",
            lineHeight : 1.56,
        },
        headings : {
            font  : "Space Grotesk, sans-serif",
            color  : defaultColours.black,
        },
        code : {
            prism : {
                tokens : {
                    property : defaultColours.green90,
                    plain    : defaultColours.slate
                },
                languages : {
                    json : {
                        tokens   : {
                            string : defaultColours.red70,
                        }
                    }
                }
            }
        }
    },

    //  BUTTON  ///////////////////////////////////////////////////////////////
    button : {
        fontFamily : "Space Grotesk, sans-serif",
    }
}
