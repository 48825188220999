import React, { useEffect } from "react";

import {
    Element,
    Row,
    Portion,
    Heading,
    Text,
    HRule, Card
} from "fictoan-react";

import { FintechStyled } from "./Fintech.styled";


export const Fintech = () => {
    useEffect(() => {
        document.title = "Fintech — FICTOAN";
    }, []);

    return (
        <FintechStyled>
            <Row sidePadding="huge" marginTop="small" marginBottom="small">
                <Portion>
                    <Heading as="h2">Fintech</Heading>
                </Portion>
            </Row>

            {/*  /////////////////////////////////////////////////////////////////////////////////////////////////  */}
            {/*  BASICS  */}
            {/*  /////////////////////////////////////////////////////////////////////////////////////////////////  */}
            <Element as="section" id="philosophy">
                <Row sidePadding="huge">
                    <Portion>
                        <Text>
                            Fintech can be grouped under these major sectors&mdash;
                        </Text>
                    </Portion>

                    <Portion desktopSpan="one-fourth">
                        <Card padding="tiny" shape="rounded" shadow="mild" className="sector-card">
                            <Text>Payments</Text>
                        </Card>
                    </Portion>

                    <Portion desktopSpan="one-fourth">
                        <Card padding="tiny" shape="rounded" shadow="mild" className="sector-card">
                            <Text>Lending</Text>
                        </Card>
                    </Portion>

                    <Portion desktopSpan="one-fourth">
                        <Card padding="tiny" shape="rounded" shadow="mild" className="sector-card">
                            <Text>Investments</Text>
                        </Card>
                    </Portion>

                    <Portion desktopSpan="one-fourth">
                        <Card padding="tiny" shape="rounded" shadow="mild" className="sector-card">
                            <Text>Payments</Text>
                        </Card>
                    </Portion>
                </Row>

                <HRule kind="primary" sideMargin="huge" />

                <Row sidePadding="huge">
                    <Portion>
                    </Portion>
                </Row>
            </Element>
        </FintechStyled>
    );
}
