// External deps
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// Internal deps
import {
    Heading,
    SidebarWrapper,
    SidebarHeader,
    SidebarItem,
    SidebarItemIcon,
    SidebarItemText,
    SidebarLink,
    SidebarNestedLinks,
    SidebarItemsGroup,
    SidebarItemsGroupHeader,
} from "fictoan-react";

// Local components


export const Sidebar = () => {
    const [ sidebarState, setSidebarState ] = useState("");

    const headerOnClick = () => {
        setSidebarState(sidebarState === "collapsed" ? "" : "collapsed");
    }

    return (
        <SidebarWrapper className={`${sidebarState === "collapsed" ? "collapsed" : ""}`}>
            <SidebarHeader onClick={headerOnClick}
            >
                <div className="header-logo">
                    <Heading as="h5">UNISpec</Heading>
                </div>
                <div className="header-icon">
                    {/*<img src={FICTOANIcon} alt="FICTOAN logo" />*/}
                </div>
            </SidebarHeader>

            {/*  HOME  ///////////////////////////////////////////////////  */}
            <NavLink exact to="/">
                <SidebarItem>
                    <SidebarItemIcon />
                    <SidebarItemText linkText="Philosophy" />
                </SidebarItem>
            </NavLink>

            {/*  GETTING STARTED  ////////////////////////////////////////  */}
            <SidebarLink>
                <NavLink exact to="/fintech">
                    <SidebarItem>
                        <SidebarItemIcon />
                        <SidebarItemText linkText="Fintech" />
                    </SidebarItem>
                </NavLink>
            </SidebarLink>
        </SidebarWrapper>
    );
}
