// External deps
import React from "react";
import {
    Switch as RouteSwitch,
    Route
} from "react-router-dom";

// Internal deps
import { ContentWrapper } from "fictoan-react";

// Local components
import { Sidebar } from "./components/Sidebar/Sidebar";
import { Fintech } from "./pages/Fintech/Fintech";

// Local assets




export const AppWithSidebar = (props) => {
    const { location } = props;

    return (
        <>
            {/*  LEFT SIDEBAR    ///////////////////////////////////////    */}
            <Sidebar location={ props.location } />

            {/*  MAIN CONTENT    ///////////////////////////////////////    */}
            <ContentWrapper>
                <RouteSwitch location={ location }>
                    <Route
                        exact
                        path="/fintech"
                        component={ Fintech }
                    />
                </RouteSwitch>
            </ContentWrapper>
        </>
    );
};
